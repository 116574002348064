import {UserInputType} from '../../../constants';

const getFocusableElement = (containerElement: HTMLElement, currentType: UserInputType): HTMLElement => {
  switch (currentType) {
    case UserInputType.Selection:
      return containerElement.querySelector('input, button, select');
    case UserInputType.Text:
      return containerElement.querySelector('textarea');
    case UserInputType.Quantity:
    case UserInputType.SubscriptionPlan:
      return containerElement.querySelector('input');
  }
};

export const scrollAndFocusUserInput = (
  currentType: UserInputType,
  currentElement: HTMLElement,
  isQuickView: boolean
) => {
  const {top, bottom} = currentElement.getBoundingClientRect();
  const isInViewport = top >= 0 && bottom <= window.innerHeight;

  const focusableElement = getFocusableElement(currentElement, currentType);
  if (isQuickView || isInViewport) {
    focusableElement?.focus();
  } else {
    currentElement.scrollIntoView({behavior: 'auto', block: 'center'});
    focusableElement?.focus({
      preventScroll: true,
    });
  }
};
