import React, {FC, useEffect, useState, useRef} from 'react';
import {classes as stylableClasses, st} from './ProductCustomText.st.css';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {ProvidedGlobalProps, withGlobalProps} from '../../../providers/globalPropsProvider';
import {TextArea} from 'wix-ui-tpa/cssVars';
import {UserInputType} from '../../../constants';
import {scrollAndFocusUserInput} from '../utils/scrollAndFocusUserInput';

export enum ProductCustomTextDataHooks {
  container = 'product-custom-text-container',
  textArea = 'product-custom-text-text-area',
}
export interface ProductCustomTextProps extends IProvidedTranslationProps, ProvidedGlobalProps {
  handleOnChange(textAreaElemValue: string): void;
  isRequired: boolean;
  maxLength: number;
  title: string;
  errorIndex: number;
}

const ProductCustomTextComponent: FC<ProductCustomTextProps & IProvidedTranslationProps> = ({
  handleOnChange,
  isRequired,
  maxLength,
  title,
  errorIndex,
  t,
  globals: {isProductSubmitted, userInputErrors, scrollAndFocusInputTarget, isQuickView, experiments},
}) => {
  const [value, setValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  const textAreaContainerRef = useRef<HTMLDivElement>();

  useEffect(() => {
    /* istanbul ignore next */
    if (!experiments.productPageOOIScrollToError) {
      return;
    }

    const isScrollAndFocusInputTarget =
      UserInputType.Text === scrollAndFocusInputTarget?.inputType && errorIndex === scrollAndFocusInputTarget?.index;

    if (isScrollAndFocusInputTarget && textAreaContainerRef.current) {
      scrollAndFocusUserInput(UserInputType.Text, textAreaContainerRef.current, isQuickView);
    }
  }, [scrollAndFocusInputTarget, errorIndex, textAreaContainerRef, isQuickView, experiments]);

  const textAreaClasses = st(stylableClasses.customTextRoot, {
    midHeight: isFocused && maxLength > 25 && maxLength <= 250,
    fullHeight: isFocused && maxLength > 250,
  });

  const label = isRequired ? title : `${title} (${t('OPTIONAL')})`;

  return (
    <div ref={textAreaContainerRef} data-hook={ProductCustomTextDataHooks.container}>
      <TextArea
        data-hook={ProductCustomTextDataHooks.textArea}
        className={textAreaClasses}
        label={label}
        ariaLabel={label}
        onChange={(event) => setValue(event.target.value)}
        onFocus={() => setIsFocused(true)}
        onBlur={(event) => handleOnChange(event.target.value)}
        value={value}
        maxLength={maxLength}
        required={isRequired}
        showAsterisk={isRequired}
        showCharCount
        newErrorMessage
        error={isProductSubmitted && userInputErrors[UserInputType.Text][errorIndex]}
        errorMessage={t('REQUIRED_FIELD')}
      />
    </div>
  );
};

export const ProductCustomText: FC<ProductCustomTextProps> = withGlobalProps(
  withTranslations('globals.texts')(ProductCustomTextComponent)
);
